<script>
import suchImage from './such.png';
import dayjs from 'dayjs';
export default {
  data() {
    return {
      routeName: this.$route.name,
      workId: this.$route.query.workid,// 获取当前路由的名称
      makeTime: new Date(),
      okTime: new Date(),
      day: String(),
      user: {},
      info: {},
      leaveForm: {},
      rawHtml: '' // 初始值为空，稍后动态设置
    };
  },
  watch: {
    // 监听路由变化
    $route(to) {
      this.routeName = to.name;
    }
  },
  created () {
    console.log('wadawassdcas:', this.$route.query);
    this.init();

  },
  mounted() {

  },
  methods: {

    async init() {
      console.log("当前路由路径:", this.$route.path);  // 当前路由的路径
      console.log("当前路由名称:", this.routeName);    // 当前路由的名称
      if (this.routeName == "new") {
        console.log('workId:', this.$route.query.workId);
        // console.log('workCompletedId:', this.$route.query.workCompletedId);
        // console.log('docTitle:', this.$route.query.docTitle);
        // console.log('appId:', this.$route.query.appId);
        // console.log('redirectlink:', this.$route.query.redirectlink);
        // console.log( this.$route.path)
        // console.log(this.$route.params)
        await this.$axios
            .post(this.$httpUrl + "/info/getinfo", {token: this.workId})
            .then((res) => res.data)
            .then((res) => {
              this.info = res.data;
              // 将 info 中的值赋给 leaveForm
              this.leaveForm.reason = this.info.reason ;
              this.leaveForm.excuseClass = this.info.excuseClass ;
              this.leaveForm.destination = this.info.destination ;
              this.leaveForm.destinationCity = this.info.destinationCity ;
              this.leaveForm.address = this.info.address ;
              this.leaveForm.travelMode = this.info.travelMode ;
              this.leaveForm.startDate = this.info.startDate ;
              this.leaveForm.endDate = this.info.endDate ;

              console.log('info', this.info)
              console.log('leaveForm', this.leaveForm)
            });

      } else {
        const storedInfo = sessionStorage.getItem("userInfo");
        if (storedInfo) {
          this.info = JSON.parse(storedInfo);
        } else {
          console.warn("userInfo not found in sessionStorage");
        }
        const storedUser = sessionStorage.getItem("CurUser");
        if (storedUser) {
          this.user = JSON.parse(storedUser);
          if (this.$route.query.leaveForm) {
            this.leaveForm = JSON.parse(this.$route.query.leaveForm);
          } else {
            console.warn('No leaveForm data found');
          }
          // 在初始化 user 后，动态生成 rawHtml
        } else {
          console.warn("CurUser not found in sessionStorage");
        }
      }
      this.getInfoData();
    },
    getInfoData() {
      console.log("最后的"+this.info.infoName)

      let makDate = new Date(this.leaveForm.startDate);
      let twoDate = new Date(this.leaveForm.startDate);
      let endsDate = new Date(this.leaveForm.endDate);

      const minSeconds = 2 * 60 * 60; // 2 小时的秒数
      const maxSeconds = 6 * 60 * 60; // 6 小时的秒数
      const oneHourSeconds = 2 * 60 * 60; // 1 小时的秒数
      const randomSeconds = Math.floor(Math.random() * (maxSeconds - minSeconds + 1)) + minSeconds;
      const secondDiff = randomSeconds/2;
      // 将 startDate 减去随机的秒数
      console.log(randomSeconds)
      console.log(secondDiff)
      this.makeTime=makDate;
      this.okTime=twoDate;
      this.makeTime.setSeconds(-randomSeconds);
      this.okTime.setSeconds(-secondDiff);
      this.day =Math.round(parseFloat((endsDate-makDate)/(1000 * 60 * 60 * 24)));
      let titleTime = dayjs(this.makeTime ).format('YYYYMMDD');
      this.makeTime = dayjs(this.makeTime ).format('YYYY-MM-DD HH:mm:ss');
      this.okTime = dayjs(this.okTime).format('YYYY-MM-DD HH:mm:ss');
      this.leaveForm.startDate = dayjs(this.leaveForm.startDate).format('YYYY-MM-DD HH:mm:ss');
      this.leaveForm.endDate= dayjs(this.leaveForm.endDate).format('YYYY-MM-DD HH:mm:ss');
      console.log(this.makeTime);
      console.log(this.okTime);
      document.title=`${this.info.infoName}-${titleTime}`;

      this.generateRawHtml();

    },
    generateRawHtml() {
      // 生成带有动态数据的 HTML 字符串
      this.rawHtml = `
<div style="-webkit-overflow-scrolling: touch; overflow: auto; position: absolute; bottom: 4em;top: 0px;left: 0px;width: 100%;" id="#app">
  <div id="appContent" class="appContent" style="overflow: hidden; -webkit-transform: translateZ(0); ">
    <div style="width: 100%; height: 100%; overflow: auto; background-color: rgb(255, 255, 255);">
      <div style="min-height: 100%; font-size: 14px; overflow: hidden; background-color: rgb(240, 240, 240); user-select: text;">
        <div class="css css8d83b6318c3b4252b4039b0c006de157" mwftype="form" id="8d83b631-8c3b-4252-b403-9b0c006de157" style="background-color: rgb(240, 240, 240);">
          <div id="div" mwftype="div" style="margin: auto;">
            <div id="label" mwftype="label" style="height: 40px; background-color: rgb(255, 255, 255); line-height: 40px; padding: 0px 15px; color: rgb(60, 118, 183); font-size: 16px; font-weight: bold; border-bottom: 0px solid rgb(204, 204, 204); text-align: center;">本科生外出请假</div>
            <div id="div_2" mwftype="div" style="min-height: auto; overflow: hidden; height: auto;">
              <div id="div_1" mwftype="div" style="height: 30px; min-height: 30px; margin: 5px 0px; float: left; font-size: 12px;">
                <div id="label_1" mwftype="label" style="float: left; font-size: 12px;">拟稿人：</div>
                <div id="label_2" mwftype="label" style="float: left;">${this.info.infoName}</div>
              </div>
              <div id="div_1_1" mwftype="div" style="height: 30px; min-height: 30px; margin: 5px 0px; float: right; font-size: 12px;">
                <div id="label_1_1" mwftype="label" style="float: left; font-size: 12px;">拟稿时间：</div>
                <div id="label_2_1" mwftype="label" style="float: left;">${this.makeTime}</div>
              </div>
            </div>
            <div id="tab" mwftype="tab" style="">
              <div style="height: 40px; background: rgb(255, 255, 255); border-top: 0px solid rgb(204, 204, 204); border-bottom: 0px solid rgb(204, 204, 204);">
                <div class="tabNodeContainerRight" style="width: 10px; height: 22px; margin-top: 3px; border: 1px solid rgb(238, 238, 238); margin-right: 3px; float: right; background:  center center no-repeat; cursor: pointer; display: none;">
                  <div class="MWFMenu" style="border: 0px solid rgb(102, 102, 102); background-color: rgb(102, 102, 102); padding: 1px; overflow: hidden; display: none; z-index: 999; opacity: 0; position: absolute; float: left; min-width: 140px; border-radius: 3px; box-shadow: rgb(51, 51, 51) 0px 0px 10px; cursor: default;">
                    <div id="menu" style="border-width: 1px; border-style: solid; border-color: rgb(238, 238, 238); border-radius: 3px; background-color: rgb(238, 238, 238); padding: 3px; overflow: hidden; cursor: default;"></div>
                  </div>
                </div>
                <div class="tabNodeContainerLeft" style="margin-right: 15px; height: 30px;">
                  <div class="tabNodeContainerArea">
                    <div id="tab_tab$Page" mwftype="tab$Page" style="position: relative; margin-left: 2px; margin-right: 0px; float: left; height: 40px; cursor: pointer; min-width: 70px; background: rgb(255, 255, 255); border-width: 0px 0px 2px; border-top-style: initial; border-right-style: initial; border-bottom-style: solid; border-left-style: initial; border-top-color: initial; border-right-color: initial; border-bottom-color: rgb(191, 99, 100); border-left-color: initial; border-image: initial; margin-top: 0px;">
                      <div style="color: rgb(251, 71, 71); line-height: 40px; text-align: center; margin-right: 4px; margin-left: 4px; font-size: 14px; margin-top: 0px;"> 基本信息</div>
                    </div>
                    <div id="tab_tab$Page_1" mwftype="tab$Page" style="position: relative; margin-left: 2px; margin-right: 0px; float: left; height: 40px; cursor: pointer; min-width: 70px; background: rgb(255, 255, 255); border: 0px;">
                      <div style="color: rgb(102, 102, 102); line-height: 40px; text-align: center; margin-right: 4px; margin-top: 0px; margin-left: 4px; font-size: 14px;">正文附件</div>
                    </div>
                    <div id="tab_tab$Page_2" mwftype="tab$Page" style="position: relative; margin-left: 2px; margin-right: 0px; float: left; height: 40px; cursor: pointer; min-width: 70px; background: rgb(255, 255, 255); border: 0px;">
                      <div style="color: rgb(102, 102, 102); line-height: 40px; text-align: center; margin-right: 4px; margin-top: 0px; margin-left: 4px; font-size: 14px;">流程记录</div>
                    </div>
                    <div id="tab_tab$Page_3" mwftype="tab$Page" style="position: relative; margin-left: 2px; margin-right: 0px; float: left; height: 40px; cursor: pointer; min-width: 70px; background: rgb(255, 255, 255); border: 0px;">
                      <div style="color: rgb(102, 102, 102); line-height: 40px; text-align: center; margin-right: 4px; margin-top: 0px; margin-left: 4px; font-size: 14px;">流程监控</div>
                    </div>
                  </div>
                </div>
              </div>
              <div name="MWFcontentNodeContainer" style="line-height: normal; clear: both; overflow: hidden;">
                <div style="overflow: hidden; display: block; background-color: transparent; opacity: 1;">
                  <div id="tab_tab$Content" mwftype="tab$Content" style="padding: 10px 0px;">
                    <div id="div_3" mwftype="div" style="background-color: rgb(255, 255, 255); border: 1px solid rgb(204, 204, 204);">
                      <div mwftype="label" id="label_5" style="height: 40px; background-color: rgb(255, 255, 255); line-height: 40px; padding: 0px 15px; color: rgb(60, 118, 183); font-size: 18px; font-weight: bold; border-bottom: 0px solid rgb(204, 204, 204);">基本信息</div>
                      <div id="table_3" mwftype="table" style="margin-bottom: 10px; margin-top: 10px;">
                        <table width="100%" align="center" border="0" cellspacing="0" cellpadding="5" style="border-collapse: separate; border-top: 1px solid rgb(0, 0, 0); border-left: 1px solid rgb(0, 0, 0);">
                          <tbody>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_12" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_27" style="">学生姓名</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_14" colspan="1" rowspan="1" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div id="name" mwftype="Textfield" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border-width: 0px; border-bottom-style: solid; border-bottom-color: rgb(204, 204, 204); border-top-style: initial; border-top-color: initial; border-left-style: initial; border-left-color: initial; border-right-style: initial; border-right-color: initial; line-height: 26px;" nodeid="name">${this.info.infoName}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_4" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_8" style="">学号</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_8" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div id="code" mwftype="Textfield" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="code">${this.info.infoSid}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_52" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_15" style="">手机号码</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_53" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div id="mobile" mwftype="Textfield" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="mobile">${this.info.infoPhone}</div>
                            </td>
                          </tr>
                          <tr>
                            <td id="table_3_table$Td" mwftype="table$Td" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 200px; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_20" style="">性别</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_24" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);" colspan="1" rowspan="1">
                              <div mwftype="Radio" id="radio_1" style="" nodeid="radio_1">${this.info.infoSex}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_58" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_3" style="">所在学院</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_59" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div id="dept" mwftype="Textfield" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="dept">${this.info.infoCollege}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_5" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_10" style="">年级专业</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_10" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Textfield" id="major" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="major">${this.info.infoClass}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_64" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_43" style="">现住公寓名称</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_65" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Textfield" id="home" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="home">${this.info.infoDorm}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_13" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_42" style="">现住宿舍号</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_15" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Textfield" id="homeNo" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="homeNo">${this.info.infoDormid}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_70" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_38" style="">家长姓名</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_71" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Textfield" id="parent" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="parent">${this.info.infoPname}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_76" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_39" style="">家长联系电话</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_77" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Textfield" id="parentMobile" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="parentMobile">${this.info.infoPphone}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_37" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_36" style="">请假类型</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_38" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Select" id="select" style="border: 0px solid rgb(204, 204, 204); margin-top: 5px; color: rgb(102, 102, 102); font-size: 18px; height: 26px; text-align: right;" nodeid="select">事假</div>
                              <div mwftype="Textfield" id="title" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102); display: none;" nodeid="title">${this.info.infoName}-20240928</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_82" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_21" style="">请假开始时间
                              </div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_83" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Calendar" id="outDate" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="outDate">${this.leaveForm.startDate}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_88" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_22" style="">请假结束时间</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_89" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Calendar" id="endDate" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="endDate">${this.leaveForm.endDate}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_11" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_12" style="float: left; margin-top: 8px;">共多少天</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_16" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Textfield" id="days" style="overflow: visible; position: relative; margin-right: 0px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; text-align: center; margin-top: 10px; color: rgb(102, 102, 102);" nodeid="days">${this.day}</div>
                              <div mwftype="Textfield" id="teacherObj" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); color: rgb(102, 102, 102); display: none;" nodeid="teacherObj">[{"name":"侯睿","unique":"b8d750bc-9021-47e1-bcc8-895cf69a2f45","distinguishedName":"侯睿@b8d750bc-9021-47e1-bcc8-895cf69a2f45@I","person":"侯睿@20070000035@P","unit":"软件工程系@000756@U","unitName":"软件工程系","unitLevel":4,"unitLevelName":"内蒙古工业大学/内蒙古工业大学教职工/数据科学与应用学院/软件工程系","orderNumber":11,"major":true}]</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_29" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div id="label_7" mwftype="label" style="">请假详细原因</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_31" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Textarea" id="reason" style="position: relative; padding-right: 2px; border: 0px solid rgb(204, 204, 204); color: rgb(102, 102, 102);" nodeid="reason">${this.leaveForm.reason}</div>
                              <div mwftype="Textfield" id="teacher" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102); display: none;" nodeid="teacher">侯睿@20070000035@P</div>
                              <div mwftype="Textfield" id="teacherNo" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102); display: none;" nodeid="teacherNo">20070000035</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_94" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_37" style="">请假期间是否离校</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_95" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Radio" id="radio_3" style="" nodeid="radio_3">是</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_7" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_11" style="">目的地</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_9" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Select" id="province" style="border: 0px solid rgb(204, 204, 204); color: rgb(102, 102, 102); font-size: 18px; margin-top: 5px; height: 26px; text-align: right;" nodeid="province">${this.leaveForm.destination}</div>
                              <div mwftype="Select" id="city" style="border: 0px solid rgb(204, 204, 204); color: rgb(102, 102, 102); font-size: 18px; margin-top: 5px; height: 26px; text-align: right;" nodeid="city">${this.leaveForm.destinationCity}</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_100" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div id="label_28" mwftype="label" style="">详细地址</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_101" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div id="address" mwftype="Textfield" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="address">${this.leaveForm.address}</div>
                            </td>
                          </tr>
                          <tr>
                            <td id="table_3_table$Td_2" mwftype="table$Td" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_14" style="">出行方式</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_25" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);" colspan="1" rowspan="1">
                              <div id="thing" mwftype="Textfield" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="thing">${this.leaveForm.travelMode}</div>
                            </td>
                          </tr>
                          <tr>
                            <td id="table_3_table$Td_6" mwftype="table$Td" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_40" style="">请假期间是否回校住宿</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_27" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);" colspan="1" rowspan="1">
                              <div mwftype="Radio" id="radio_4" style="" nodeid="radio_4">否</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_1" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_4" style="">佐证材料
                              </div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_3" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);" colspan="1" rowspan="1">
                              <div mwftype="attachment" id="attachment_1" style="">
                                <img src="https://i.ibb.co/tL74dbM/such.jpg" alt="Image">
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_106" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_19" style="">状态</div>
                            </td>
                            <td mwftype="table$Td" id="table_3_table$Td_107" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="Textfield" id="status" style="overflow: hidden; position: relative; margin-right: 20px; padding-right: 4px; border: 0px solid rgb(204, 204, 204); line-height: 26px; color: rgb(102, 102, 102);" nodeid="status">请假</div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_3_table$Td_50" colspan="2" rowspan="1" style="border-bottom: 1px solid rgb(0, 0, 0); border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="label" id="label_30" style="">学生本人承诺：</div>
                              <div mwftype="label" id="label_31" style="">1.以上填报各项信息真实准确；并已向家长或配偶汇报我的外出安排，家长或配偶对此完全知情并同意我外出； </div>
                              <div mwftype="label" id="label_32" style="">2.班主任已向我宣讲减少外出、不参加聚集活动、加强个人防护、尽量不乘坐公共交通工具等疫情防控相关要求，并已告知外出期间有关注意事项及可能存在的风险； </div>
                              <div mwftype="label" id="label_33" style="">3.外出期间我将自觉遵守国家法律法规和校纪校规，切实做好个人防护，确保通讯畅通，自觉注意人身及财产、交通、饮食、消防等安全，外出期间一切安全责任由本人承担；</div>
                              <div mwftype="label" id="label_34" style="">4.按照学校要求及时报告行程和身体健康状况，按时返校并主动销假；身体如有不适及时就医并向老师和学院报告。</div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div mwftype="label" id="label_16" style="height: 40px; background-color: rgb(255, 255, 255); line-height: 40px; padding: 0px 15px; color: rgb(60, 118, 183); font-size: 18px; font-weight: bold; border-bottom: 0px solid rgb(204, 204, 204);">审核信息</div>
                    <div mwftype="div" id="div_4" style="">
                      <div id="table" mwftype="table" style="margin-bottom: 10px; margin-top: 10px;">
                        <table border="0" cellpadding="5" cellspacing="0" width="100%" align="center" style="border-top: 1px solid rgb(0, 0, 0); border-left: 1px solid rgb(0, 0, 0);">
                          <tbody>
                          <tr>
                            <td mwftype="table$Td" id="table_table$Td_8" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 200px; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div id="label_17" mwftype="label" style="">班主任审批</div>
                            </td>
                            <td mwftype="table$Td" id="table_table$Td_5" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);" colspan="1" rowspan="1">
                              <div mwftype="log" id="log_1" style="user-select: text;">
                                <div style="overflow: hidden; margin: 0px; padding: 10px 0px; border: 1px solid rgb(204, 204, 204); background-color: rgb(255, 255, 255);">
                                  <div style="clear: both; overflow: hidden; background-color: rgb(246, 246, 246); min-height: 30px; line-height: 40px;">
                                    <div style="clear: both; overflow: hidden; line-height: 28px; font-size: 12px; background: transparent;">
                                      <div style="height: 28px; width: 28px; background-position: center center; background-repeat: no-repeat; float: left;"></div>
                                      <div style="margin-right: 10px; overflow: hidden; margin-left: 28px;">
                                        <font style="color:#ff5400;">${this.info.infoMname}</font>（${this.info.infoMclass}） 选择【同意】，提交于： ${this.okTime}，处理意见：<font style="color:#00F"></font>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_table$Td_7" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div id="label_6" mwftype="label" style="">团学办公室审批</div>
                            </td>
                            <td mwftype="table$Td" id="table_table$Td_9" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="log" id="log_2" style="user-select: text;">
                                <div style="overflow: hidden; margin: 0px; padding: 10px 0px; border: 1px solid rgb(204, 204, 204); background-color: rgb(255, 255, 255);"></div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td mwftype="table$Td" id="table_table$Td_4" style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51); border-bottom: 1px solid rgb(0, 0, 0); height: 36px; font-size: 16px; width: 30%; line-height: 36px; text-align: left; font-weight: bold; border-right: 1px solid rgb(0, 0, 0);">
                              <div id="label_9" mwftype="label" style="">分管学工副院长审批</div>
                            </td>
                            <td mwftype="table$Td" id="table_table$Td_6" style="background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); font-size: 18px; border-bottom: 1px solid rgb(0, 0, 0); text-align: right; border-right: 1px solid rgb(0, 0, 0);">
                              <div mwftype="log" id="log_3" style="user-select: text;">
                                <div style="overflow: hidden; margin: 0px; padding: 10px 0px; border: 1px solid rgb(204, 204, 204); background-color: rgb(255, 255, 255);"></div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="overflow: hidden; display: none; background-color: transparent; opacity: 0;">
                  <div id="tab_tab$Content_1" mwftype="tab$Content" style="padding: 10px 0px;">
                    <div id="htmleditor" mwftype="itmleditor" style="user-select: text;"></div>
                    <div id="attachment" mwftype="itmleditor" style="">
                      <div style="border: 1px solid rgb(182, 182, 182); min-height: 180px; background: rgb(255, 255, 255); margin-top: 10px; overflow: hidden;">
                        <div style="height: 30px; background-color: rgb(221, 221, 221); border-top: 1px solid rgb(255, 255, 255); border-bottom: 1px solid rgb(182, 182, 182); line-height: 30px; font-size: 14px; font-weight: bold; padding-left: 10px; text-align: left;">附件区域</div>
                        <div style="height: 40px;  border-top: 1px solid rgb(255, 255, 255); border-bottom: 1px solid rgb(182, 182, 182);">
                          <div style="overflow: hidden; border-bottom: 1px solid rgb(234, 235, 234); float: left; margin-left: 10px; margin-top: 5px; border-radius: 3px; ">
                            <div style="height: 26px; border: 1px solid rgb(151, 151, 151); border-radius: 3px; ">
                              <div title="上传" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                              <div title="删除" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                              <div title="替换" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                              <div class="separateNode" style="width: 1px; height: 16px; margin-top: 5px; margin-left: 5px; margin-right: 5px; border-left: 1px solid rgb(188, 188, 188); background-color: rgb(241, 241, 241); float: left;"></div>
                              <div title="下载" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                            </div>
                          </div>
                          <div style="overflow: hidden; border-bottom: 1px solid rgb(234, 235, 234); float: left; margin-left: 10px; margin-top: 5px; border-radius: 3px; ">
                            <div style="height: 26px; border: 1px solid rgb(151, 151, 151); border-radius: 3px; ">
                              <div title="列表" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                              <div title="序列" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                              <div title="图标" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                              <div title="预览" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                            </div>
                          </div>
                          <div style="overflow: hidden; border-bottom: 1px solid rgb(234, 235, 234); float: left; margin-left: 10px; margin-top: 5px; border-radius: 3px; ">
                            <div style="height: 26px; border: 1px solid rgb(151, 151, 151); border-radius: 3px; ">
                              <div title="设置附件权限" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                              <div title="纠正自动识别的图片中的文字" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                              <div class="separateNode" style="width: 1px; height: 16px; margin-top: 5px; margin-left: 5px; margin-right: 5px; border-left: 1px solid rgb(188, 188, 188); background-color: rgb(241, 241, 241); float: left;"></div>
                              <div title="附件排序" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                            </div>
                          </div>
                          <div style="overflow: hidden; border-bottom: 1px solid rgb(234, 235, 234); float: right; margin-left: 10px; margin-top: 5px; border-radius: 3px;  margin-right: 7px;">
                            <div style="height: 26px; border: 1px solid rgb(151, 151, 151); border-radius: 3px; ">
                              <div title="简洁模式" style="float: left; width: 26px; height: 26px; cursor: default;">
                                <div style="width: 26px; height: 26px; background-repeat: no-repeat; background-position: center center; "></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div draggable="true" class="contentScrollNode" style="background-color: rgb(255, 255, 255); overflow: auto; min-height: 130px;">
                          <div class="content" style="background-color: rgb(255, 255, 255); overflow: hidden; margin-right: 6px;"></div>
                        </div>
                        <div style="height: 7px; border-top: 1px solid rgb(182, 182, 182); background-color: rgb(232, 232, 232);">
                          <div style="height: 7px; cursor: row-resize;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="overflow: hidden; display: none; background-color: transparent; opacity: 0;">
                  <div id="tab_tab$Content_2" mwftype="tab$Content" style="padding: 10px 0px;">
                    <div id="log" mwftype="log" style="user-select: text;">
                      <div style="overflow: hidden; margin: 0px; border: 1px solid rgb(204, 204, 204); background-color: rgb(255, 255, 255);">
                        <div style="height: 40px; line-height: 40px; background-color: rgb(240, 240, 240); border-bottom: 1px solid rgb(204, 204, 204); font-size: 12px; overflow: hidden;">
                          <div style="height: 40px; width: 30px; background-position: center center; background-repeat: no-repeat; float: left; "></div>
                          <div style="height: 40px; line-height: 40px; float: left; font-size: 14px;">
                            <b>发起人</b>
                          </div>
                          <div style="height: 40px; width: 30px; background-position: center center; background-repeat: no-repeat; float: left; "></div>
                          <div style="height: 40px; line-height: 40px; float: left; font-size: 14px;">
                            <b>班主任</b>
                          </div>
                          <div style="height: 40px; line-height: 40px; float: left; color: rgb(0, 0, 255); margin-left: 20px; cursor: pointer; font-size: 12px;"></div>
                          <div style="height: 40px; line-height: 20px; margin-right: 5px; float: right;">
                            <b>起: </b>2024-09-11 18:29:05
                            <br>
                            <b>止: </b>2024-09-11 18:35:05
                          </div>
                        </div>
                        <div style="clear: both; overflow: hidden;">
                          <div style="clear: both; overflow: hidden; line-height: 28px; font-size: 12px; background: transparent;">
                            <div style="height: 28px; width: 28px; margin-left: 20px; background-position: center center; background-repeat: no-repeat; float: left;"></div>
                            <div style="margin-right: 10px; overflow: hidden; margin-left: 48px;">
                              <font style="color:#ff5400;">白志远</font>（软件22-3） 选择【申请】，提交于： ${this.okTime}，处理意见：<font style="color:#00F"></font>
                            </div>
                          </div>
                          <div style="margin: 5px 48px; overflow: hidden; line-height: 18px; font-size: 12px;"></div>
                        </div>
                      </div>
                      <div style="overflow: hidden; margin: 0px; border: 1px solid rgb(204, 204, 204); background-color: rgb(255, 255, 255);">
                        <div style="height: 40px; line-height: 40px; background-color: rgb(240, 240, 240); border-bottom: 1px solid rgb(204, 204, 204); font-size: 12px; overflow: hidden;">
                          <div style="height: 40px; width: 30px; background-position: center center; background-repeat: no-repeat; float: left; "></div>
                          <div style="height: 40px; line-height: 40px; float: left; font-size: 14px;">
                            <b>班主任</b>
                          </div>
                          <div style="height: 40px; width: 30px; background-position: center center; background-repeat: no-repeat; float: left; "></div>
                          <div style="height: 40px; line-height: 40px; float: left; font-size: 14px;">
                            <b>选择</b>
                          </div>
                          <div style="height: 40px; line-height: 40px; float: left; color: rgb(0, 0, 255); margin-left: 20px; cursor: pointer; font-size: 12px;"></div>
                          <div style="height: 40px; line-height: 20px; margin-right: 5px; float: right;">
                            <b>起: </b>2024-09-11 18:35:05
                            <br>
                            <b>止: </b>2024-09-11 18:41:33
                          </div>
                        </div>
                        <div style="clear: both; overflow: hidden;">
                          <div style="clear: both; overflow: hidden; line-height: 28px; font-size: 12px; background: transparent;">
                            <div style="height: 28px; width: 28px; margin-left: 20px; background-position: center center; background-repeat: no-repeat; float: left;"></div>
                            <div style="margin-right: 10px; overflow: hidden; margin-left: 48px;">
                              <font style="color:#ff5400;">侯睿</font>（软件工程系） 选择【同意】，提交于： 2024-09-11 18:41:33，处理意见：<font style="color:#00F"></font>
                            </div>
                          </div>
                          <div style="margin: 5px 48px; overflow: hidden; line-height: 18px; font-size: 12px;"></div>
                        </div>
                      </div>
                      <div style="overflow: hidden; margin: 0px; border: 1px solid rgb(204, 204, 204); background-color: rgb(255, 255, 255);">
                        <div style="height: 40px; line-height: 40px; background-color: rgb(240, 240, 240); border-bottom: 1px solid rgb(204, 204, 204); font-size: 12px; overflow: hidden;">
                          <div style="height: 40px; width: 30px; background-position: center center; background-repeat: no-repeat; float: left; "></div>
                          <div style="height: 40px; line-height: 40px; float: left; font-size: 14px;">
                            <b>选择</b>
                          </div>
                          <div style="height: 40px; width: 30px; background-position: center center; background-repeat: no-repeat; float: left; "></div>
                          <div style="height: 40px; line-height: 40px; float: left; font-size: 14px;">
                            <b>结束</b>
                          </div>
                          <div style="height: 40px; line-height: 40px; float: left; color: rgb(0, 0, 255); margin-left: 20px; cursor: pointer; font-size: 12px;"></div>
                          <div style="height: 40px; line-height: 20px; margin-right: 5px; float: right;">
                            <b>起: </b>2024-09-11 18:41:33
                            <br>
                            <b>止: </b>2024-09-11 18:41:33
                          </div>
                        </div>
                        <div style="clear: both; overflow: hidden;">
                          <div style="clear: both; overflow: hidden; line-height: 28px; font-size: 12px; background: transparent;">
                            <div style="height: 28px; width: 28px; margin-left: 20px; background-position: center center; background-repeat: no-repeat; float: left;"></div>
                            <div style="margin-right: 10px; overflow: hidden; margin-left: 48px;">系统自动提交于：2024-09-11 18:41:33</div>
                          </div>
                          <div style="margin: 5px 48px; overflow: hidden; line-height: 18px; font-size: 12px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="overflow: hidden; display: none; background-color: transparent; opacity: 0;">
                  <div id="tab_tab$Content_3" mwftype="tab$Content" style="padding: 10px 0px;">
                    <div id="monitor" mwftype="log" style="">
                      <div style="height: 656px; border: 1px solid rgb(153, 153, 153);"></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="o2_form_mobile_actions" style="height: 4em;overflow: hidden; border-top: 1px solid #dfdfdf; display: block; position: absolute; bottom: 0px; background: #ffffff; width: 100%; z-index:100;"></div>
`;
    }
  }
};
</script>


<template>
  <div v-html="rawHtml"></div>
</template>
